import React from 'react'
import {Helmet} from 'react-helmet'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

function HomePage( {data} ) {
	const {allQuotesJson} = data

	const randomQuote = Math.floor( Math.random() * allQuotesJson.nodes.length )

	return (
		<Layout>
			<SEO title="LA Art Exchange - Frame Store in Los Angeles & Santa Monica"/>
			<Helmet titleTemplate="%s"/>

			<main className="container">
				<ul className="grid lg:grid-cols-3 gap-4 lg:gap-2">
					<li className="lg:shadow col-auto lg:col-span-3 relative">
						<Img
							className="mb-4 lg:mb-0"
							fluid={data.hero.childImageSharp.fluid}
						/>
						<div
							className="py-8 px-16 text-center bg-tan lg:max-w-sm lg:ml-10 lg:mb-20 lg:absolute left-0 bottom-0 z-10 shadow">
							<p>From unique construction to mass production.</p>
							<p className="text-purple-900 font-bold mb-2">
								No job is too large or too small.
							</p>
						</div>
					</li>
					<li className="shadow col-auto lg:col-span-1 flex items-end relative h-64 lg:h-auto">
						<Link
							aria-label="gallery"
							className="p-6 lg:p-1 block"
							to="/gallery"
						>
							<div className="bg-white-transparent px-4 py-2 relative z-10">
								<h2 className="uppercase tracking-widest">Be Inspired</h2>
								<p className="text-xs uppercase">View the gallery</p>
							</div>
							<Img
								alt="picture frame"
								className="w-full h-full left-0 top-0"
								fluid={data.frames.childImageSharp.fluid}
								style={{position: `absolute`}}
							/>
						</Link>
					</li>
					<li className="shadow col-auto lg:col-span-1 bg-purple-900 text-purple-100 relative">
						<Link
							aria-label="our services"
							className="p-6 block"
							to="/services"
						>
							<h2 className="uppercase text-lg mb-6 tracking-widest">
								Our Services
							</h2>
							<ul className="text-sm lg:text-xs xl:text-sm flex flex-wrap flex-row">
								<li className="mb-2 w-1/2">Fine Art Framing</li>
								<li className="mb-2 w-1/2">Shadowboxes</li>
								<li className="mb-2 w-1/2">Mirrors</li>
								<li className="mb-2 w-1/2">Poster Mounting</li>
								<li className="mb-2 w-1/2">Corporate Services</li>
								<li className="mb-2 w-1/2">Art Consulting</li>
								<li className="mb-2 w-1/2">Jerseys &amp; Textiles</li>
								<li className="mb-2 w-1/2">Canvas Stretching</li>
								<li className="mb-2 w-1/2">Gallery Wall Layout</li>
								<li className="mb-2 w-1/2">Pick Up &amp; Delivery</li>
								<li className="mb-2 w-1/2">Art Restoration</li>
								<li className="mb-2 w-1/2">In-Home Consultations</li>
								<li className="mb-2 w-1/2">Acrylic/Plexi Boxes</li>
								<li className="mb-2 w-1/2">Remote Design Services</li>
							</ul>
						</Link>
					</li>
					<li className="shadow col-auto lg:col-span-1 bg-gray-300 flex items-end h-64 lg:h-auto relative">
						<a
							aria-label="gallery"
							className="p-6 lg:p-1 block"
							href="https://www.instagram.com/laartexchange/"
							rel="noreferrer"
							target="_blank"
						>
							<div className="bg-white-transparent p-4 uppercase text-sm tracking-widest relative z-10">
								<span className="font-bold">Instagram</span> @ LAARTEXCHANGE
							</div>
							<Img
								alt="picture frames"
								className="top-0 left-0 w-full h-full"
								fluid={data.framesAlt.childImageSharp.fluid}
								style={{position: `absolute`}}
							/>
						</a>
					</li>
					<li className="shadow col-auto lg:col-span-1 bg-gray-700 text-white p-6">
						<h2 className="uppercase text-lg mb-6 tracking-widest">
							What Folks Say
						</h2>
						<figure>
							{allQuotesJson && (
								<div key="person">
									<p className="text-sm mb-6">
										&lsquo;{allQuotesJson.nodes[randomQuote].quote}&rsquo;
									</p>
									<figcaption className="text-xs italic">
										- {allQuotesJson.nodes[randomQuote].person}
									</figcaption>
								</div>
							)}
						</figure>
					</li>
					<li className="shadow col-auto lg:col-span-2 bg-purple-900 h-64 lg:h-auto">
						<Link
							aria-label="360 Tour"
							className="p-6 lg:p-1 relative flex items-end h-full"
							to="/tour"
						>
							<div className="text-white p-4 uppercase leading-none lg:w-1/2 lg:ml-auto relative z-10">
								<span className="font-bold">360&deg; tour</span>
								<br/>
								<span className="font-light text-sm">
                  where the magic begins
                </span>
							</div>
							<Img
								className="top-0 left-0 w-full h-full"
								fluid={data.instagram.childImageSharp.fluid}
								style={{position: `absolute`}}
							/>
						</Link>
					</li>
				</ul>
				<div className="mt-8 text-sm">
					<p>ABOUT</p>
					<p>For over 30 years, we’ve had the privilege of framing your fine art, keepsakes, posters, and memorabilia. All materials we use are archival and acid free. Custom Framing is our passion and your trust is our reward. L.A. Art Exchange’s new showroom in Santa Monica has the largest collection of frames in Los Angeles and beyond, and we strive to find the perfect solution for you, whatever the scope or budget of your project.</p>
				</div>
			</main>
		</Layout>
	)
}

export const query = graphql`
  query {
    allQuotesJson {
      nodes {
        quote
        person
      }
    }
    frames: file(relativePath: { eq: "gallery/home.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero: file(relativePath: { eq: "hero/Rauschenbergs.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "sample-remove-later/instagram.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    framesAlt: file(relativePath: { eq: "sample-remove-later/frames2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default HomePage
